<template>
  <div id="student" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form @submit.prevent="passes(initializeWithdrawal)">
            <SdInput
              type="text"
              rules="required"
              label="Name"
              v-model="student.name"
              placeholder="Name"
              readonly
            />

            <SdInput
              type="text"
              rules="required"
              label="School Class"
              v-model="student.schoolClass.name"
              placeholder="School Class"
              readonly
            />

            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized is-pulled-right mt-3"
            >
              Withdraw
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <!-- Delete -->
    <app-modal
      id="withdraw-student"
      context="update"
      @update="submit"
      :show-modal.sync="isModalOpen"
    >
      <template slot="add"
        ><p>You want to withdraw this student?</p>
        <p>Proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'student',
  data() {
    return {
      schoolId: null,
      schoolClassId: null,
      termId: null,
      pageTitle: 'Withdraw',
      student: {
        id: null,
        name: '',
        schoolClass: {
          name: null,
        },
      },
    }
  },
  apollo: {
    student: {
      query: gql`
        query studentId($id: ID!) {
          student(id: $id) {
            id
            name
            schoolClass {
              id
              name
            }
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
    },
  },
  watch: {
    student(data) {
      this.schoolClassId = data.schoolClass.id
      this.$store.commit('setSubMenus', [
        {
          name: 'Student Info',
          route: `/school/${this.$route.params.school_id}/school_class/${data.schoolClass.id}/student_info/${this.$route.params.id}`,
        },
      ])
    },
  },
  methods: {
    initializeWithdrawal() {
      this.openModal()
    },
    submit() {
      const id = parseInt(this.student.id)
      this.$apollo
        .mutate({
          mutation: gql`
            mutation withdrawStudent($id: Int!) {
              withdrawStudent(input: { id: $id }) {
                errors
              }
            }
          `,
          variables: {
            id: id,
          },
        })
        .then((response) => {
          if (response.data.withdrawStudent.errors.length === 0) {
            this.closeModal()
            this.$buefy.notification.open({
              duration: 5000,
              message: 'Successfully withdrawn',
              position: 'is-top',
              type: 'is-info',
              hasIcon: true,
            })
            this.$router.push(
              `/school/${this.schoolId}/students/${this.schoolClassId}`
            )
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    fetchTerm().then((term) => {
      this.termId = term.id
    })
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
  },
}
</script>
